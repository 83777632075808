import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) {
    return (
      <div>
        <p>Page not found!</p>
      </div>
    );
  }

  return (
    <div>
      <p>Loading</p>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `mourne-textiles`,
  linkResolver: ({ node, key, value }) => doc => {
    // Your link resolver
    if (doc.type === 'home') {
      return `/`;
    } else if (doc.type === 'contact_us') {
      return `/contact`;
    } else if (doc.type === 'gallery') {
      return `/gallery`;
    } else if (doc.type === 'journal') {
      return `/journal`;
    } else if (doc.type === 'journal_article') {
      return `/journal/${doc.uid}`;
    } else if (doc.type === 'project') {
      return `/contract/${doc.uid}`;
    } else if (doc.type === 'page') {
      return `/${doc.uid}`;
    } else {
      return `/`;
    }
  },
});
